import { DecimalPipe, LowerCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';

import {
  AutotestAttributeDirective,
  IdCardEntity,
  IdCardQuantityStatusComponent,
  LazyImageModule,
  SharedPipesModule,
  UniqFactoryIdCardAdapter,
} from '../../../';
import { ActionsStatusComponent } from '../../../modules/id-card-content/id-card-actions/components/actions-status/actions-status.component';
import { IdCardAction } from '../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { MetadataInformationManagerComponent } from '../../../modules/metadata-status-manager/metadata-information-manager/metadata-information-manager.component';
import { UniqPriceComponent } from '../../../uniq/uniq-price/uniq-price.component';
import { UniqTypeIconComponent } from '../../../uniq/uniq-type-icon/';
import { UniqFactoryRouteService } from '../../services/uniq-factory-route.service';
import { IUniqFactory } from '../../uniq-factory.interface';
import { MetadataStatus } from '../../uniq-factory-metadata.interface';

export interface IUniqFactoryCardConfig {
  showPrice: boolean;
}

@UntilDestroy()
@Component({
  selector: 'ultra-uf-card',
  templateUrl: './uf-card.component.html',
  styleUrls: ['./uf-card.component.scss'],
  standalone: true,
  imports: [
    LowerCasePipe,
    DecimalPipe,
    AutotestAttributeDirective,
    SharedPipesModule,
    IdCardQuantityStatusComponent,
    UniqTypeIconComponent,
    ActionsStatusComponent,
    UniqPriceComponent,
    LazyImageModule,
    NgbTooltip,
    MetadataInformationManagerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UfCardComponent {
  readonly actions = IdCardAction;
  readonly metadataStatusEnum = MetadataStatus;

  private _uniqFactory: IUniqFactory;
  @Input() set uniqFactory(uniqFactory: IUniqFactory) {
    this._uniqFactory = uniqFactory;
    this.idCardEntity = this.idCardAdapter.transform(uniqFactory);
  }
  get uniqFactory(): IUniqFactory {
    return this._uniqFactory;
  }

  @Input() config: IUniqFactoryCardConfig = {
    showPrice: true,
  };

  idCardEntity: IdCardEntity;

  private idCardAdapter: UniqFactoryIdCardAdapter = new UniqFactoryIdCardAdapter();
  private uniqFactoryRouteService = inject(UniqFactoryRouteService);

  navigateToUniqFactoryPage() {
    this.uniqFactoryRouteService.openUniqFactoryPage(this.uniqFactory.id);
  }
}
