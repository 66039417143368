<div class="uf-card uf-card--hover-bg position-relative d-flex" (click)="navigateToUniqFactoryPage()">
  @if (idCardEntity?.metadata?.restricted && idCardEntity.metadata.status === metadataStatusEnum.VALID) {
    <div class="uf-card__badge">
      <i
        placement="top"
        container="body"
        ngbTooltip="Restricted content"
        tooltipClass="transparent-red-tooltip"
        class="icon icon-block-24 bg-red-light"></i>
    </div>
  }
  <div class="uf-card-container d-flex flex-column mw-100">
    <div class="uf-card-top d-flex flex-column">
      <div
        class="uf-card__media uf-card__media--{{
          idCardEntity.metadata?.status | lowercase
        }} d-flex align-items-center justify-content-center">
        @if (idCardEntity.metadata && idCardEntity.metadata.status !== metadataStatusEnum.VALID) {
          <ultra-metadata-information-manager
            [metadataStatus]="idCardEntity?.metadata?.status"
            [shortMessage]="true"
            [contactLinkOnNewLine]="true"
            shortMessageClassName="gap-8 flex-column background-transparent text-center"
            messageWrapperClassName="" />
        } @else {
          <ultra-lazy-image
            [defaultImage]="idCardEntity?.medias?.square?.uri | imgSourceReplace: 'cardPreview'"
            imgClassName="object-fit-cover" />
        }
      </div>

      <div class="uf-card__info">
        <span class="d-flex w-100">
          <span class="f-size-12 text-white-alpha-5 me-1" i18n>by</span>
          <span
            class="f-size-12 text-white-alpha-5 content-ellipsis"
            ultraAutotestAttribute="uf-card-creator-name-content"
            >{{ idCardEntity?.creator?.name }}</span
          >
        </span>

        <div class="w-100">
          <div class="d-flex">
            <span
              class="text-capitalize content-ellipsis fw-bold f-size-16 text-white"
              ultraAutotestAttribute="uf-card-title-content"
              >{{ idCardEntity?.info.title }}</span
            >
          </div>

          <div class="d-flex">
            <span class="text-capitalize content-ellipsis fw-bold f-size-16 text-white-alpha-5">{{
              idCardEntity?.info.subTitle
            }}</span>
          </div>
        </div>

        <div class="w-100 mt-2 position-relative">
          <div
            class="uf-card__purchase-status d-flex flex-row align-self-center"
            ultraAutotestAttribute="uf-card-purchase-status-container">
            <ultra-id-card-quantity-status [quantityAvailability]="idCardEntity.quantity" class="me-1" />

            <ultra-actions-status
              class="me-1"
              [period]="idCardEntity.tradingPeriod"
              [action]="actions.TRADABILITY"
              [availability]="idCardEntity.tradingPeriod?.resaleAvailability" />

            <ultra-actions-status
              class="me-1"
              [period]="idCardEntity.transferPeriod"
              [action]="actions.TRANSFERABILITY"
              [availability]="idCardEntity.transferPeriod?.transferAvailability" />
          </div>

          @if ({ value: idCardEntity?.content?.contentType }; as type) {
            <div class="uf-card__token-type position-absolute top-0 start-0">
              <div class="token-type position-relative d-inline-flex flex-row align-self-start align-items-center">
                <ultra-uniq-type-icon
                  class="token-type__icon"
                  size="sm"
                  opacity="70"
                  [type]="type.value?.toLowerCase()" />
                <span class="text-capitalize f-size-14 text-white-alpha-7 caption-regular">{{
                  type.value | lowercase
                }}</span>
              </div>
            </div>
          }
        </div>
      </div>
    </div>

    <div class="uf-card-bottom content-ellipsis" [class.opacity-0]="idCardEntity?.metadata?.restricted">
      @if (idCardEntity?.quantity?.quantityLeft !== '0') {
        <span class="uf-card__price text-ultra-purple-light f-size-16 fw-700">
          @if (config.showPrice && idCardEntity?.prices; as prices) {
            <ultra-uniq-price
              type="uniqfactory"
              [symbol]="prices[0]?.currency?.symbol"
              [price]="prices[0]?.amount"
              ultraAutotestAttribute="uf-card-price-content" />
          }
        </span>
      } @else {
        <span class="uf-card__price text-white-alpha-5 f-size-16 fw-700" i18n>Sold Out</span>
      }
    </div>
  </div>
</div>
